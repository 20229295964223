*
{
    box-sizing: border-box;
}

body
{
    --bg: #EFF2F7;
    --headerBG: #FFFFFF;
    --blue: #127FFF;
    --green: #30d27a;
    --red: #E63756;
    --orange: #F5803D;
    --grey: #AAB8C9;
    --paragraph: #1F212B;
    --subheading: #AEBBCC;
    --heading: #061425;
    --navigation-bg: #fff;
    --input-bg: #fff;
    --label: #555;
    --input-border: #D0D9E3;
    --box-bg: #fff;
    --paragraph-landing: #5F7084;
    --ring-bg: #EFF2F7;

    background-color: var(--bg);
}

body.dark
{
    --bg: #061425;
    --headerBG: #061425;
    --blue: #07a4bf;
    --green: #30d27a;
    --red: #E63756;
    --orange: #F5803D;
    --grey: #AAB8C9;
    --heading: #EFF2F7;
    --paragraph: #fff;
    --subheading: #5F7084;
    --navigation-bg: #0E1D30;
    --input-bg: #0E1D30;
    --label: #fff;
    --input-border: #182C45;
    --box-bg: #0E1D30;
    --paragraph-landing: #AAB8C9;
    --ring-bg: #0E1D30;

    
    background-color: var(--bg);
}


@font-face {
    font-family: 'Cerebri Sans Pro';
    src: url(./assets/fonts/cerebri-sans-pro-regular.ttf);
    font-weight: 400;
}

@font-face {
    font-family: 'Cerebri Sans Pro';
    src: url(./assets/fonts/cerebri-sans-pro-medium.ttf);
    font-weight: 500;
}

@font-face {
    font-family: 'Cerebri Sans Pro';
    src: url(./assets/fonts/cerebri-sans-pro-bold.ttf);
    font-weight: 600;
}

@font-face {
    font-family: 'Cerebri Sans Pro';
    src: url(./assets/fonts/cerebri-sans-pro-extrabold.ttf);
    font-weight: 700;
}

@font-face {
    font-family: 'Cerebri Sans Pro';
    src: url(./assets/fonts/cerebri-sans-pro-heavy.ttf);
    font-weight: 800;
}

body {
    margin: 0;
    font-family: 'Cerebri Sans Pro', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: 0.2s ease;
}

a
{
    text-decoration: none;
    outline: none;
    cursor: pointer;

    &:hover
    {
        text-decoration: none;
    }
}

svg
{
    max-width: 100%;
    height: auto;
}

button,
button:focus,
input[type="button"]
{
    outline: none;
}

h1
{
    color: var(--heading);
    font-size: 90px;
    font-weight: 800;
    line-height: 90px;
}

h2
{
    color: var(--heading);
    font-size: 60px;
    font-weight: 800;
    line-height: 70px;
}

h3
{
    color: var(--heading);
    font-size: 36px;
    font-weight: 700;
    line-height: 46px;
}

h4
{
    color: var(--heading);
    font-size: 30px;
    font-weight: 600;
    line-height: 44px;
}

h5
{
    color: var(--heading);
    font-size: 24px;
    font-weight: 600;
    line-height: 34px;
}

h6
{
    color: var(--heading);
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
}

p
{
    color: var(--paragraph);
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
}

h1, h2, h3, h4, h5, h6, p
{
    margin: 0 0;
}

@media (max-width: 767px) {
    h1
    {
        font-size: 60px;
        font-weight: 800;
        line-height: 60px;
    }

    h2
    {
        font-size: 40px;
        font-weight: 800;
        line-height: 50px;
    }

    h3
    {
        font-size: 30px;
        font-weight: 700;
        line-height: 36px;
    }

    h4
    {
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;
    }

    h5
    {
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
    }

    h6
    {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
    }

    p
    {
        color: var(--paragraph);
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
}

.disable input,
.disable button,
.disable a
{
    opacity: 0.3;
}

button:disabled
{
    cursor: not-allowed;
}

.wrapper
{
    max-width: 1270px;
    width: 90%;
    margin: 0 auto;
    display: block;
}

.empty-message
{
    font-size: 16px;
    text-align: center;
    padding: 20px 0;
    color: var(--subheading);
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
}

.Toastify__toast--success
{
    .Toastify__toast-body
    {
        svg
        {
            fill: var(--green);
        }
    }
}

.Toastify__toast--error
{
    .Toastify__toast-body
    {
        svg
        {
            fill: var(--red);
        }
    }
}

.Toastify__progress-bar--success
{
    background-color: var(--green) !important;
}

.Toastify__progress-bar--error
{
    background-color: var(--red) !important;
}

.Toastify__toast-theme--light
{
    background: var(--box-bg) !important;
    border: 1px solid var(--input-border);
}

.Toastify__close-button
{
    color: var(--heading);

    svg
    {
        fill: var(--heading) !important;
        opacity: 0.4;
    }
}

.Toastify .Toastify__toast-body div
{
    font-family: 'Cerebri Sans Pro';
    font-size: 14px;
    color: var(--heading);
    font-weight: 600;
}





.pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;

    .page-item
    {

        &.active 
        {
            .page-link
            {
                color: #fff;
                background-color: var(--primary);
            }
        }

        .page-link 
        {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            padding: unset;
            width: 30px;
            height: 30px;
            font-weight: 500;
            font-size: 15px;
            margin: 0 5px;
            cursor: pointer;
            background-color: var(--box-bg);
            color: var(--paragraph);
            border-color: var(--input-border);
            opacity: 1;
            
            svg
            {
                height: 13px;
                fill: var(--heading);
            }
        }

        &.disabled
        {
            .page-link
            {
                opacity: .4;
            }
        }

    }
}



.badge
{
    padding: 8px 20px;
    font-size: 14px;
    font-weight: 600;

    &.green
    {
        background-color: rgba($color: #39D9AE, $alpha: 0.1);
        color: var(--green);
    }

    &.red
    {
        background-color: rgba($color: #E63756, $alpha: 0.1);
        color: var(--red);
    }

    &.orange
    {
        background-color: rgba($color: #F5803D, $alpha: 0.1);
        color: var(--orange);
    }

    &.grey
    {
        background-color: rgba($color: #AAB8C9, $alpha: 0.1);
        color: var(--grey);
    }

    &.green-fill
    {
        background-color: rgba($color: #39D9AE, $alpha: 1);
        color: #fff;
    }

    &.red-fill
    {
        background-color: rgba($color: #E63756, $alpha: 1);
        color: #fff;
    }

    &.orange-fill
    {
        background-color: rgba($color: #F5803D, $alpha: 1);
        color: #fff;
    }

    &.grey-fill
    {
        background-color: rgba($color: #AAB8C9, $alpha: 1);
        color: #fff;
    }
}