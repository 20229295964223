.header
{
	width: 100%;
	display: block;

	.wrapper
	{
		padding: 30px 0;
		border-bottom: 1px solid var(--input-border);
	}

	h3
	{
		color: var(--heading);
	}

	p 
	{
		color: var(--subheading);
		text-transform: uppercase;
	}
}