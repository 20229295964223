.entries
{
	padding: 30px 0 100px;

	@media (max-width: 767px) {
		padding: 30px 0 100px;
	}

	.entries-table 
	{
		margin-top: 30px;

		.head
		{
			.tr
			{
				height: 30px;
				font-size: 12px;
				font-weight: 500;
				text-transform: uppercase;
				color: var(--subheading);
				align-items: center;
				transition: all 0.3s ease;

				.entry-wrap
				{
					display: grid;
					grid-template-columns: 0.2fr 1.3fr 0.3fr 0.5fr 0.5fr 0.5fr 0.5fr 0.8fr 0.2fr;
					grid-gap: 20px;
					font-size: 12px;
					font-weight: 500;
					text-transform: uppercase;
					color: var(--subheading);
					height: 0;
					align-items: center;
					height: 30px;

					@media ( max-width: 767px )
					{
						grid-template-columns: 0.1fr 0.9fr 0.1fr;
					}
				}

				.entry-wrap > div
				{
					@media (max-width: 767px) {
						display: none;
					}
				}
				
				.entry-wrap > div:first-child,
				.entry-wrap > div:nth-child(2),
				.entry-wrap > div:last-child
				{
					@media (max-width: 767px) {
						display: block;
						height: unset;
					}
				}

				.entry-wrap > .trash
				{
					text-align: right;
				}

				.entry-wrap > .check div,
				.entry-wrap > .trash div
				{
					position: relative;
					transform: unset;
					left: unset;
					top: unset;
				}
			}
		}

		.tr
		{
			align-items: center;

			.entry-wrap
			{
				display: grid;
				grid-template-columns: 0.2fr 1.3fr 0.3fr 0.5fr 0.5fr 0.5fr 0.5fr 0.8fr 0.2fr;
				grid-gap: 20px;

				@media (max-width: 767px) {
					grid-gap: 10px;
					grid-template-columns: 0.1fr 0.9fr 0.1fr;
					padding: 0 20px;
					justify-content: space-between;
					height: 100%;
				}
			}

			.location { 
				text-align: left; 
				cursor: pointer;
				color: #000;

				&.smaller
				{
					font-size: 14px;
				}
			}

			.status,
			.check,
			.harmless,	
			.suspicious,
			.malicious,
			.undetected,
			.trash
			{ 
				text-align: center; 
				position: relative;

				.trash-icon
				{
					cursor: pointer;
					position: absolute;
					top: 50%;
					transform: translateX(-50%) translateY(-50%);
					left: 50%;		
				}
			}

			.harmless,	
			.suspicious,
			.malicious,
			.undetected,
			.updated
			{
				transition: 0.5s ease;

				&.active
				{
					color: var(--heading);
				}
			}

			.updated
			{ 
				text-align: right; 
			}

			.status.with-tooltip
			{
				position: relative;
			}
			.status.with-tooltip:before
			{
				content: "";
				width: 0;
				height: 0;
				border-left: 7px solid transparent;
				border-right: 7px solid transparent;
				border-top: 8px solid var(--heading);
				border-radius: 10px;
				position: absolute;
				top: -1px;
				left: 50%;
				transform: translateX(-50%);
				opacity: 0;
				visibility: hidden;
			}

			.status.with-tooltip:after
			{
				content: attr(data-tooltip);
				position: absolute;
				top: -22px;
				left: 50%;
				transform: translateX(-50%);
				padding: 3px 7px;
				background-color: var(--heading);
				font-size: 12px;
				border-radius: 3px;
				color: #fff;
				opacity: 0;
				visibility: hidden;
				color: var(--bg);
			}

			&:hover 
			{
				.status.with-tooltip:after,
				.status.with-tooltip:before
				{
					opacity: 1;
					visibility: visible;
					transition: 0.3s ease-in-out;
				}

				.status.with-tooltip:after
				{
					top: -29px;
				}

				.status.with-tooltip:before
				{
					top: -8px;
				}
			}

			&.Undetected
			{
				cursor: default !important;

				&:hover
				{
					.location
					{
						color: inherit !important;
					}
				}
			}
		}

		.body
		{
			display: flex;
			flex-direction: column;
			position: relative;
			min-height: 60px;
			grid-gap: 10px;

			.tr 
			{

				.loader
				{
					margin: 0;

					&::after
					{
						width: 20px;
						height: 20px;
						border: 2px solid var(--blue);
						border-color: var(--blue) transparent var(--blue) transparent;
					}
				}
			}

			&.loading
			{
				.tr:not(.new-entry) 
				{
					opacity: 0.3 !important;
				}
			}

			> div:not(.input-wrap)
			{
				display: grid;
				grid-gap: 10px;
			}

			> .input-wrap
			{
				display: unset;
			}

			.location
			{
				padding: 15px 0;
				transition: 0.3s ease;

				&:hover
				{
					color: var(--blue);
				}
			}

			.tr 
			{
				// cursor: pointer;
				position: relative;
				text-transform: unset;
				height: 80px;
				border-radius: 5px;
				transition: all 0.3s ease-in-out;
				opacity: 0;
				display: none;

				&.fade-enter
				{
					opacity: 0;
					display: block;
				}

				&.fade-enter-done
				{
					opacity: 1;
					display: block;
				}
				
				.entry-wrap
				{
					align-items: center;
					font-size: 16px;
					font-weight: 500;
					color: var(--heading);
					background-color: var(--box-bg);
					text-transform: unset;
					opacity: 1;
					height: 80px;
					overflow: visible;
					transition: all 0.3s ease-in-out;
				}

				.updated
				{
					font-size: 14px;
					font-weight: 500;
					line-height: 20px;

					span
					{
						display: block;
						text-align: right;
						font-size: 13px;
						color: var(--subheading);
					}
				}

				.trash
				{
					text-align: right;
					cursor: pointer;
					position: relative;

					span
					{
						margin: 0 0 0 auto;
						display: block;
						width: 5px;
						height: 5px;
						margin: 4px auto 4px auto;
						border-radius: 100%;
						background-color: var(--subheading);
					}

					.dropdown
					{
						width: auto;
						position: absolute;
						top: calc(100% + 10px);
						right: 0;
						border-radius: 5px;
						background-color: var(--bg);
						border: 1px solid var(--input-border);
						z-index: 1;
						overflow: hidden;
						padding: 0;

						li
						{
							display: flex;
							align-items: center;
							padding: 15px 50px 15px 25px;
							border-bottom: 1px solid var(--input-border);
							transition: 0.3s ease;

							&:hover
							{
								background-color: var(--blue);
								color: #fff;

								svg
								{
									stroke: #fff;
								}
							}
						
							&:last-child
							{
								border-bottom: none;
							}

							svg
							{
								width: 17px;
								height: 17px;
								max-width: unset;
								margin-right: 15px;
								transition: 0.3s ease;
							}
						}
					}
				}

				&.new-entry
				{
					display: grid;
					grid-template-columns: 1fr 50px 50px;
					grid-gap: 20px;
					font-size: 14px;
					font-weight: 500;
					text-transform: uppercase;
					color: var(--subheading);
					padding: 0 20px 0 20px;
					height: 0;
					overflow: hidden;
					align-items: flex-start;
					background-color: var(--box-bg);
					box-shadow: 0 10px 40px 0 rgba($color: #000000, $alpha: .1);
					z-index: 1;

					&.fade-enter
					{	
						opacity: 1;
						height: 0;
						padding: 0 20px 0 20px;
						overflow: hidden;

						.entry-wrap
						{
							opacity: 0;
						}
					}
					
					&.fade-enter-active
					{
						opacity: 1;
						height: 100%;
						padding: 12px 20px 12px 20px;
						overflow: hidden;

						.entry-wrap
						{
							opacity: 1;
						}
					}

					&.fade-enter-done
					{
						opacity: 1;
						height: 100%;
						padding: 12px 20px 12px 20px;
						overflow: visible;

						.entry-wrap
						{
							opacity: 1;
						}
					}

					&.fade-exit 
					{
						opacity: 1;
						padding: 12px 20px 12px 20px;
						height: 100%;
					}
					
					&.fade-exit-active
					{
						opacity: 0;
						padding: 0 20px 0 20px;
						height: 0;
					}
					
					&.fade-exit-done
					{
						opacity: 0;
						padding: 0 20px 0 20px;
						height: 0;
					}
				}
			}


			// mobile design
			.tr
			{
				&.mobile
				{
					height: 100%;
					
					.entry-wrap
					{
						height: 100%;
						display: flex;
						grid-gap: 10px;
						flex-direction: column;
						padding: 15px 20px;
						border-radius: 5px;

						.top
						{
							width: 100%;
							display: grid;
							grid-gap: 10px;
							grid-template-columns: 0.1fr 0.7fr 0.1fr 0.1fr;
							justify-content: space-between;
							font-size: 16px;

							.check
							{
								text-align: left;
								justify-content: flex-start;
							}

							.trash
							{
								justify-content: flex-end;

								span
								{
									margin: 4px 0 4px auto
								}
							}
						}

						.bottom
						{
							width: 100%;
							display: flex;
							justify-content: space-between;
							text-align: left;
							font-size: 14px;

							div
							{
								text-align: left;
								color: var(--subheading);

								b 
								{
									color: var(--heading);
								}
							}
						}
					}

					&.fade-enter
					{	
						opacity: 1;
						overflow: hidden;
						margin-bottom: 0;

						.entry-wrap
						{
							opacity: 0;
						}
					}
					
					&.fade-enter-active
					{
						opacity: 1;
						margin-bottom: 10px;
						overflow: hidden;

						.entry-wrap
						{
							opacity: 1;
						}
					}

					&.fade-enter-done
					{
						opacity: 1;
						margin-bottom: 10px;
						overflow: visible;

						.entry-wrap
						{
							opacity: 1;
						}
					}

					&.fade-exit 
					{
						opacity: 1;
						height: 100%;
					}
					
					&.fade-exit-active
					{
						opacity: 0;
					}
					
					&.fade-exit-done
					{
						opacity: 0;
					}

					// end mobile design
				}
			}
			
		}
	}
}

// custom checkbox
.checkbox
{
	display: none;
}


.check 
{
	display: flex;
	justify-content: center;
	align-content: center;
	position: relative;

	> div
	{
		position: absolute;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
		left: 50%;
	}

	label
	{
		width: 25px;
		height: 25px;
		cursor: pointer;
		display: flex;
		border-radius: 3px;
		margin: 0;
		box-shadow: inset 0 0 0 2px var(--input-border);
		background-color: var(--input-bg);
		transition: 0.2s ease;
	}

	.checkbox:checked + label 
	{
		box-shadow: inset 0 0 0 7px var(--input-border);
		background-color: var(--blue);
	}
}

// Custom Sort by
.sort-by
{
	display: inline-flex;
	background-color: var(--box-bg);
	border-radius: 3px;
	height: 50px;
	padding: 5px 10px;
	font-size: 14px;
	align-items: center;
	justify-content: space-between;
	grid-gap: 10px;
	letter-spacing: 0;
	border: 1px solid var(--input-border);

	select
	{
		outline: none;
		border: none;
		font-weight: 500;
		background: none;
		color: var(--heading);
		cursor: pointer;
	}

	p
	{
		font-size: 14px;
		color: var(--subheading);
	}
}

.new-entry-loading
{
	height: 50px;
	width: 120px;
	grid-column-start: auto;
	grid-column-end: 3;

	.loader
	{
		position: relative !important;
		right: unset !important;
		width: 100%;
		height: 50px;
		margin: 0;
	}

}

.new-entry-added
{
	width: 120px;
	height: 50px;
	grid-column-start: auto;
	grid-column-end: 3;
	display: flex;
	justify-content: center;
	align-items: center;

	path {
		stroke-dasharray: 25;
		stroke-dashoffset: 25px;
		animation: check 0.4s cubic-bezier(0.86, 0, 0.07, 1) forwards;
		stroke: var(--green);
	}
	  
	svg {
		width: 30px;
		height: 30px;
	}
	  	
	@keyframes check {
		0%   { stroke-dashoffset: 25px; stroke-dasharray: 25px;}
		100%  { stroke-dashoffset: 0; }
	}
	
}
