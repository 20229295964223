.button
{
	height: 50px;
	padding: 0 30px;
	min-width: 170px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	outline: none;
	border: none;
	border-radius: 5px;
    font-family: 'Cerebri Sans Pro', sans-serif;
	font-size: 16px !important;
	font-weight: 600;
	transition: box-shadow 0.3s ease;
	cursor: pointer;

	&.button-loading
	{
		font-size: 0 !important;

		&::after
		{
			content: " ";
			display: block;
			width: 30px;
			height: 30px;
			margin: 8px;
			text-align: center;
			border-radius: 50%;
			border: 4px solid #fff;
			border-color: #fff transparent #fff transparent;
			animation: loader 1.2s linear infinite;
		}
	}

	&.button-xs
	{
		padding: 12px 10px;
		min-width: 100px;
		height: unset;
	}

	@media (max-width: 767px) {
		min-width: unset;
	}

	&.blue
	{
		background-color: var(--blue);
		color: #fff;
		
		&:hover
		{
			box-shadow: 0 8px 20px 0 rgba($color: #000, $alpha: .15);
		}

		&:active
		{
			box-shadow: 0 0 0 0 rgba($color: #000, $alpha: .1);
		}
	}

	&.red
	{
		background-color: var(--red);
		color: #fff;
		
		&:hover
		{
			box-shadow: 0 8px 20px 0 rgba($color: #000, $alpha: .15);
		}

		&:active
		{
			box-shadow: 0 0 0 0 rgba($color: #000, $alpha: .1);
		}
	}

	&.white
	{
		background-color: var(--box-bg);
		color: var(--heading);
		
		&:hover
		{
			box-shadow: 0 8px 20px 0 rgba($color: #000, $alpha: .15);
		}

		&:active
		{
			box-shadow: 0 0 0 0 rgba($color: #000, $alpha: .1);
		}
	}

	&.red-effect
	{
		background-color: rgba($color: #E63756, $alpha: .2);
		border: 1px solid rgba($color: #E63756, $alpha: .3);
		color: var(--red);
		
		&:hover
		{
			box-shadow: 0 8px 20px 0 rgba($color: #000, $alpha: .15);
		}

		&:active
		{
			box-shadow: 0 0 0 0 rgba($color: #000, $alpha: .1);
		}
	}

	&.blue-effect
	{
		background-color: rgba($color: #127FFF, $alpha: .1);
		border: 1px solid rgba($color: #127FFF, $alpha: .3);
		color: var(--blue);
		
		&:hover
		{
			box-shadow: 0 8px 20px 0 rgba($color: #000, $alpha: .15);
		}

		&:active
		{
			box-shadow: 0 0 0 0 rgba($color: #000, $alpha: .1);
		}
	}

	&[disabled]
	{
		opacity: 0.4;

		&:hover
		{
			box-shadow: unset;
		}
	}

	// outline button with white border
	&.outline
	{
		border: 1px solid #C0C6D9;
		background-color: transparent;
		color: var(--paragraph);
		
		&:hover
		{
			box-shadow: 0 5px 15px 0 rgba($color: #000, $alpha: .1);
		}

		&:active
		{
			box-shadow: 0 0 0 0 rgba($color: #000, $alpha: .1);
		}
	}


	// outline button with blue border
	&.outline-blue
	{
		border: 3px solid var(--blue);
		background-color: transparent;
		color: var(--blue);
		
		&:hover
		{
			box-shadow: 0 5px 15px 0 rgba($color: #000, $alpha: .1);
			background-color: var(--blue);
			color: #fff;
		}

		&:active
		{
			box-shadow: 0 0 0 0 rgba($color: #000, $alpha: .1);
		}
	}

	// dark button
	&.dark
	{
		background-color: var(--heading);
		color: var(--box-bg);
		
		&:hover
		{
			box-shadow: 0 5px 15px 0 rgba($color: #000, $alpha: .1);
		}

		&:active
		{
			box-shadow: 0 0 0 0 rgba($color: #000, $alpha: .1);
		}
	}


	// new entry button
	&.discard-new-entry,
	&.new-entry
	{
		padding: 0 0;
		width: 50px;
		height: 50px;
		min-width: unset;
		background-color: #30d27a;
		color: #fff;

		&:hover
		{
			box-shadow: 0 5px 15px 0 rgba($color: #000, $alpha: .1);
		}

		&:active
		{
			box-shadow: 0 0 0 0 rgba($color: #000, $alpha: .1);
		}

		svg
		{
			width: 18px;
			height: 18px;
		}
	}
	
	// discard new entry button
	&.discard-new-entry
	{
		background-color: var(--box);
		border: 1px solid var(--input-border);
	}
}

.dark 
{
	.button.outline
	{
		border: 1px solid #254469;
	}
}
