.not-found
{
	width: 100%;
	height: 100vh;
	padding: 100px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	svg
	{
		max-height: 500px;
	}

	h3
	{
		color: var(--heading);
		margin-top: 30px;
	}
}