nav
{
	position: absolute;
	width: 100%;
	z-index: 10;
	padding: 40px;

	.wrapper	
	{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	ul
	{
		display: flex;
		align-items: center;
		list-style: none;
		margin: 0;
		color: #fff;

		li
		{
			min-width: 22px;
			margin: 0 15px;
			font-size: 16px;
			font-weight: 600;
			text-transform: uppercase;
			cursor: pointer;

			@media (max-width: 767px) {
				margin: 0 8px;
			}

			&:last-child
			{
				margin-right: 0;
			}
			
			> div
			{
				display: flex;
				color: #fff;
				min-width: 26px;
				transition: 0.3s ease;
				
				&.button
				{
					padding: 8px 20px;
					height: unset;
					min-width: unset;
				}
			}
		}
	}
}

header.landing
{
	padding: 130px 0 300px;
	background-color: #061425;
	position: relative;
	transition: background-color 0.1s ease-in-out;

	.wave
	{
		position: absolute;
		bottom: -5px;
		left: 0;
		width: 100%;
		z-index: 1;
	}

	@media (max-width: 767px) {
		background-position: bottom left;
		background-size: cover;
		padding: 10px 0 100px;
	}

	.bg
	{
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		bottom: 0;
		z-index: 0;

		img
		{
			width: 100%;
			height: 100%;
			position: absolute;
			bottom: 0;
			left: 0;

			@media (max-width: 767px) {
				width: 100%;
				height: 100%;
			}
		}
	}

	.content
	{
		margin-top: 150px;

		@media (max-width: 767px) {
			margin-top: 80px;
		}

		h1
		{
			color: #fff;

			@media (max-width: 767px) {
				font-size: 60px;
				line-height: 60px;
			}
	
			span 
			{
				color: var(--red);
			}
		}
	
		p
		{
			font-size: 22px;
			color: #fff;
			line-height: 40px;
			margin-top: 30px;
			opacity: .8;
		}
	}

}

.features
{
	background-color: var(--headerBG);
	padding: 100px 0;
	transition: background-color 0.1s ease-in-out;

	@media (max-width: 767px) {
		padding: 0 0 0;
	}

	.icon 
	{
		margin-bottom: 20px;

		@media (max-width: 767px) {
			display: inline-block;
			vertical-align: middle;
			margin-bottom: 0;

			svg
			{
				width: 40px;
				height: 40px;
			}
		}
	}

	h4
	{
		margin-bottom: 20px;
		color: var(--paragraph);

		@media (max-width: 767px) {
			display: inline-block;
			vertical-align: middle;
			line-height: 40px;
			margin-bottom: 0;
			margin-left: 20px;
		}
	}

	p
	{
		color: var(--paragraph);

		@media (max-width: 767px) {
			margin-top: 20px;
		}
	}
}

.get-started
{
	padding: 100px 0;
	background-color: var(--headerBG);

	@media (max-width: 767px) {
		padding: 50px 0;
	}

	h2
	{
		color: var(--heading);
	}

	p
	{
		width: 100%;
		margin-top: 50px;
		font-size: 18px;
		line-height: 36px;
		max-width: 500px;
		color: var(--paragraph);

		@media (max-width: 767px) {
			margin-top: 20px;
		}
	}
}

.dark .illustration-circle
{
	&::before,
	&-left::before
	{
		filter: brightness(0.3);
		opacity: .3;
	}
}
.illustration-circle
{
	position: relative;
	z-index: 0;

	svg
	{
		z-index: 1;
		width: 100%;
	}

	&:before
	{
		content: "";
		position: absolute;
		top: -150px;
		left: 0;
		width: 800px;
		height: 800px;
		border-radius: 100%;
		z-index: -2;
		background: rgb(255,255,255);
		background: -moz-linear-gradient(45deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 42%, rgba(239,242,247,1) 100%);
		background: -webkit-linear-gradient(45deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 42%, rgba(239,242,247,1) 100%);
		background: linear-gradient(45deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 42%, rgba(239,242,247,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#eff2f7",GradientType=1);


		@media (max-width: 1750px) {
			width: 600px;
			height: 600px;
		}

		@media (max-width: 1250px) {
			width: 500px;
			height: 500px;
			top: -80px;
			right: 0;
			left: unset;
		}

		@media (max-width: 767px) {
			content: unset;
		}
	}

	&-left
	{
		position: relative;
		z-index: 0;

		svg
		{
			z-index: 1;
			width: 100%;
		}

		&:before
		{
			content: "";
			position: absolute;
			top: -50px;
			right: 0;
			width: 700px;
			height: 700px;
			border-radius: 100%;
			z-index: -2;
			background: rgb(255,255,255);
			background: -moz-linear-gradient(-45deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 42%, rgba(239,242,247,1) 100%);
			background: -webkit-linear-gradient(-45deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 42%, rgba(239,242,247,1) 100%);
			background: linear-gradient(-45deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 42%, rgba(239,242,247,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#eff2f7",GradientType=1);
			
			@media (max-width: 1750px) {
				width: 600px;
				height: 600px;
			}
	
			@media (max-width: 1250px) {
				width: 500px;
				height: 500px;
				top: -60px;
				right: unset;
				left: 0;
			}

			@media (max-width: 767px) {
				content: unset;
			}
		}
	}

}


.how-it-works
{
	padding: 100px 0;
	background-color: var(--headerBG);

	@media (max-width: 767px) {
		padding: 0 0 50px 0;
	}

	.title
	{
		h2
		{
			color: var(--heading);
		}

		p
		{
			max-width: 700px;
			width: 100%;
			margin-top: 50px;
			font-size: 18px;
			line-height: 36px;
			color: var(--paragraph);

			@media (max-width: 767px) {
				margin-top: 20px;
			}
		}
	}
}


.numbered-list
{
	list-style: none;
	counter-reset: section;
	padding-left: 0;

	@media (max-width: 767px) {
		margin-top: 20px;
	}

	li
	{
		padding: 0 0 30px 65px;
		position: relative;
		color: var(--heading);
		
		&:before
		{
			counter-increment: section;
			content: counters(section, ".");
			position: absolute;
			top: 0;
			left: 0;
			width: 50px;
			height: 50px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: rgba($color: #127FFF, $alpha: .1);
			color: var(--blue);
			border-radius: 100%;
			font-weight: 700;
			font-size: 24px;
		}

		h4
		{
			font-size: 24px;
			font-weight: 900;
			margin-bottom: 0;
		}
	
		p
		{
			margin: 0;
		}
	}

}

.landing-dashboard
{
	padding: 100px 0 220px;
	background: var(--box-bg);

	@media (max-width: 767px) {
		padding: 0px 0 200px;
	}

	.title
	{

		h2
		{
			color: var(--heading);
		}

		p
		{
			max-width: 700px;
			width: 100%;
			margin-top: 50px;
			font-size: 18px;
			line-height: 36px;
			color: var(--paragraph);

			@media (max-width: 767px) {
				margin-top: 20px;
			}
		}
	}

	.image-wrap 
	{
		position: relative;

		svg
		{
			max-width: 100%;
			height: 100%;
		}
	}
}

footer 
{
	background-image: url('../../assets/images/landing/footer.png');
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: top center;
	background-color: #000;
	padding: 100px 0;

	@media (max-width: 767px) {
		padding: 200px 0 100px;
		background-size: 100% 100%;
	}

	.image-wrap 
	{
		position: relative;
		top: -300px;
		margin-bottom: -400px;

		@media (max-width: 767px) {
			top: -380px;
			margin-bottom: -400px;
		}

		svg
		{
			max-width: 100%;
		}
	}
}

.popup
{
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba($color: #030b16, $alpha: .7);
	z-index: 2;

	visibility: hidden;
	opacity: 0;

	transition: 0.3s ease;

	&.fade-enter-done
	{
		opacity: 1;
		visibility: visible;
	}
	
	.popup-container
	{
		padding: 40px 30px;
		background-color: var(--bg);
		border-radius: 5px;
		width: 100%;
		max-width: 500px;
		border: 1px solid var(--input-border);

		.auth-title
		{
			margin-bottom: 40px;
			color: var(--heading);
		}
	}
}

