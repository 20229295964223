.preferences
{
	margin-top: 50px;
}

.hooks 
{

	.head
	{
		width: 100%;
		display: grid;
		justify-content: space-between;
		align-items: center;
		grid-template-columns: .4fr 1fr 0.1fr 0.1fr;
		font-size: 12px;
		font-weight: 500;
		text-transform: uppercase;
		color: var(--subheading);

		p
		{
			font-size: 14px;
			font-weight: 600;
			text-transform: uppercase;
			color: var(--subheading);
		}
	}

	.body
	{
		display: flex;
		flex-direction: column;
		list-style: none;
		margin: 0;
		padding: 0;
		grid-gap: 10px;
		margin-top: 4px;

		li
		{
			display: grid;
			justify-content: space-between;
			align-items: center;
			grid-template-columns: .4fr 1fr 0.1fr 0.1fr;
			margin: 0;
			padding: 10px 10px;
			background-color: var(--box-bg);
			border: 1px solid var(--input-border);
			border-radius: 5px;
			
			p.type
			{
				span
				{
					width: auto;
					display: inline-flex;
					justify-content: center;
					align-items: center;
					padding: 0px 8px 0 10px;
					font-size: 14px;
					border-radius: 3px;
					background-color: rgba(245, 128, 61, .1);
					color: var(--orange);
					opacity: 1;
					transition: 0.1s ease;
					font-weight: 500;
					text-transform: capitalize;
				}

				&.dropdown
				{
					cursor: pointer;

					span
					{
						&::after
						{
							content: "";
							width: 10px;
							height: 10px;
							position: relative;
							margin-left: 5px;
							display: flex;
							justify-content: center;
							align-items: center;
							background-image: url('../../assets/images/angle-down.svg');
							background-position: center center;
							background-repeat: no-repeat;
							opacity: 0.4;
							transition: 0.3s ease;
						}
					}
				}
				
				&.opened
				{
					span { 
						&::after
						{
							transform: rotate(-180deg);
						}
					}
				}

				&.webhook
				{
					span
					{
						color: rgba(18, 127, 255, 1);
						background-color: rgba(18, 127, 255, .1)
					}
				}
			}

			.location
			{
				font-size: 16px;

				input:disabled
				{
					background-color: transparent;
				}
				input
				{
					width: 100%;
					padding: 0;
					height: auto;
					border: none;
					outline: none;
					color: var(--paragraph);
					background-color: transparent;
					padding: 5px 0 5px;
					box-shadow: inset 0px 0 0px 0px var(--subheading);
					transition: 0.3s ease;

					&.edit
					{
						text-align: left;
						background: transparent;
						box-shadow: inset 0px -1px 0px 0px var(--subheading);
					}
				}
			}

			.edit
			{
				text-align: center;
				cursor: pointer;
			}

			.delete
			{
				text-align: right;
				cursor: pointer;

				svg
				{
					height: 20px;
					width: auto;
				}
			}

			.hook-stats
			{
				width: 100%;
				grid-column-start: 1;
    			grid-column-end: 5;
				display: none;
				opacity: 0;
				visibility: hidden;
				transition: 0.3s ease;
				height: 0%;
				
				&.fade-enter
				{
					display: flex;
					height: 100%;
				}

				&.fade-enter-done
				{
					display: flex;
					justify-content: space-between;
					opacity: 1;
					height: 100%;
					visibility: visible;
				}


				p
				{
					font-size: 16px;
					line-height: 110%;
				}

				.subheading
				{
					font-size: 12px;
					margin-bottom: 0;
					line-height: 20px;
				}
			}
		}
	}

}



.hook-logs
{

	.head
	{
		width: 100%;
		display: grid;
		justify-content: space-between;
		align-items: center;
		grid-template-columns: 2fr 1fr 1fr 1fr 30px;
		padding: 0 20px;
		font-size: 12px;
		font-weight: 500;
		text-transform: uppercase;
		color: var(--subheading);

		> p
		{
			font-size: 14px;
			font-weight: 600;
			text-transform: uppercase;
			color: var(--subheading);
			text-align: center;
			justify-content: center;

			&:first-child
			{
				text-align: left;
			}

			&:last-child
			{
				text-align: right;
			}
		}
	}

	.body
	{
		display: flex;
		flex-direction: column;
		list-style: none;
		margin: 0;
		padding: 0;
		grid-gap: 10px;
		margin-top: 4px;

		li
		{
			padding: 20px;
			background-color: var(--box-bg);
			border: 1px solid var(--input-border);
			border-radius: 5px;

			.top-information
			{
				display: grid;
				justify-content: space-between;
				align-items: center;
				grid-template-columns: 2fr 1fr 1fr 1fr 30px;
				margin: 0;
				text-align: center;
				cursor: pointer;
				
				> div
				{
					.response
					{
						margin: 0 auto;
						text-align: center;
					}

					&:first-child
					{
						text-align: left;
					}

					&.status
					{
						text-align: right;
					}

					&.arrow
					{
						text-align: right;
						
						svg
						{
							width: 14px;
							opacity: 0.3;
							transition: 0.3s ease-in-out;
						}
					}
				}
			}

			&.active
			{
				.arrow
				{
					svg
					{
						transform: rotate(180deg);
					}
				}

				.log-information
				{
					height: 100%;
					
					table
					{
						position: relative;
					}
				}
			}

			.log-information
			{
				background-color: var(--box-bg);
				grid-column-start: 1;
				grid-column-end: 6;
				overflow: hidden;
				height: 0;
				position: relative;
				transition: 0.3s ease-in-out;
			
				table 
				{
					margin-top: 20px;
					width: 100%;
					border-top: 1px solid var(--input-border);
					border-spacing: 20px;
					border-collapse: inherit;
					position: absolute;
					top: 0;
					left: 0;

					tr 
					{
						
						td
						{
							border-radius: 3px;
							padding: 15px;
							vertical-align: top;

							&:first-child
							{
								width: 10%;
							}

							&:last-child
							{
								background-color: var(--bg);
							}

							textarea
							{
								width: 100%;
								height: 350px;
								resize: none;
								background-color: transparent;
								border: transparent;
							}
						}
					}
				}
			}
		}
	}
}


