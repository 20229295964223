.box
{
	width: 100%;
	padding: 15px 25px;
	background-color: var(--box-bg);
	border: 1px solid var(--input-border);
	border-radius: 5px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (max-width: 767px) {
		padding: 10px 15px;
	}

	.box-information
	{
		flex: 1;

		h3
		{
			color: var(--heading);

			@media (max-width: 767px) {
				font-size: 24px;
				line-height: 36px;
			}
		}
		
		p
		{
			color: var(--subheading);
			font-weight: 500;

			@media (max-width: 767px) {
				font-size: 14px;
				line-height: 20px;
			}
		}
	}

	.box-icon
	{
		svg
		{
			width: 30px;
			height: 30px;

			@media (max-width: 767px) {
				width: 24px;
				height: 24px;
			}
		}
	}

}