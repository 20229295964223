.auth
{
	width: 100%;
	min-height: 100vh;
	padding: 30px 0;
	display: flex;
	flex-wrap: wrap;
	background-position: 0%;
	background-size: 50% 100%;
	background-repeat: no-repeat;

	@media (max-width: 1000px) {
		background-size: 0% 0%;
		min-height: unset;
	}

	@media (max-width: 767px) {
		padding: 0;
	}

	&-illustration
	{
		width: 50%;
		padding: 50px 5%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		@media (max-width: 1000px) {
			display: none;
		}

		@media (max-width: 1500px) {
			padding: 0px 5%;
		}

		&-wrap 
		{
			max-width: 550px;

			svg
			{
				@media (max-width: 1500px) {
					max-width: 300px;
				}

				@media (max-width: 1250px) {
					max-height: 200px;
				}
			}
		}

		h2
		{
			width: 100%;
			text-align: left;
			max-width: 550px;
			color: #fff;
			margin: 40px 0;
		}

		p
		{
			color: #fff;
		}
	}

	&-content
	{
		width: 50%;
		flex: 1;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 200px 5% 200px;

		@media (max-width: 1500px) {
			padding: 100px 5% 100px;
		}

		@media (max-width: 767px) {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 130px 5% 80px;
		}

		&-wrap
		{
			width: 100%;
			max-width: 450px;
			margin: 0 auto;

			@media (max-width: 767px) {
				padding: 5%;
				background-color: var(--box-bg);
				border: 1px solid var(--input-border);
				box-shadow: 0 5px 20px 0 rgba(0,0,0,.1);
				border-radius: 5px;
			}
		}

		&_logo
		{
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			text-align: center;

			@media (max-width: 1000px) {
				display: flex;
				align-items: center;
			}

			@media (max-width: 767px) {
				top: 30px;
			}

			&__symbol
			{
				margin-bottom: 20px;

				@media (max-width: 1500px) {
					margin-bottom: 10px;
				}

				@media (max-width: 1000px) {
					margin-bottom: 0px;
				}

				svg 
				{
					max-height: 80px;

					@media (max-width: 1500px) {
						max-height: 60px;
					}
				}
			}

			&__text
			{
				svg 
				{
					@media (max-width: 1500px) {
						max-height: 20px;
					}
				}
			}
		}

		.auth-title
		{
			margin-bottom: 50px;
			color: var(--heading);
		}

		.auth-ask
		{
			font-size: 18px;
			margin-top: 20px;
		}

		a
		{
			font-size: 18px;
			color: var(--blue);
			font-weight: 500;
		}
	}
}