.stats
{
	margin-top: 30px;

	.boxes
	{
		.box
		{
			transition: 0.3s ease-in-out;
		}		
		@media (min-width: 1100px) {
			&:hover
			{
				.box
				{
					opacity: 0.4;
				}
			}
		}
		.col-6
		{
			// cursor: pointer;
			opacity: 1;
			transition: 0.3s ease-in-out;
			cursor: pointer;

			@media (min-width: 1100px) {
				&:hover
				{
					.box
					{
						opacity: 1;
					}
				}
			}
		}

		.opacity
		{
			.box
			{
				opacity: 0.4;
			}

			&:hover
			{
				.box
				{
					opacity: 1;
				}
			}
		}

		.active
		{
			opacity: 1 !important;
		}
	}
	
	@media (max-width: 767px) {
		margin-top: 20px;
	}

	.subheading
	{
		@media (max-width: 767px) {
			margin-bottom: 0;
		}
	}
}