.settings
{
	padding: 0 0 100px;
}

.subheading
{
	color: var(--subheading);
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 700;
	margin-bottom: 20px;
}