.input-wrap
{
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;
	position: relative;

	@media (max-width: 767px) {
		margin-bottom: 20px;
	}

	&:last-child
	{
		margin-bottom: 0;
	}

	label
	{
		font-size: 16px;
		color: var(--label);
		margin-bottom: 10px;
	}

	input,
	select,
	textarea
	{
		width: 100%;
		height: 50px;
		background-color: var(--input-bg);
		border: 1px solid var(--input-border);
		color: var(--paragraph);
		border-radius: 5px;
		outline: none;
		transition: all 0.3s ease;
		padding: 0 20px;
		font-size: 16px;
		cursor: pointer;
	}

	textarea
	{
		padding: 12px 20px;
		resize: none;
		height: unset;
		transition: unset;
	}

	input:focus
	{
		border-color: var(--blue);
	}
}

input::placeholder { color: rgba($color: #000000, $alpha: .2); }
input::-moz-placeholder { color: rgba($color: #000000, $alpha: .2); }
input::-webkit-input-placeholder { color: rgba($color: #000000, $alpha: .2); }

.dark input::placeholder { color: rgba($color: #ffffff, $alpha: .2); }
.dark input::-moz-placeholder { color: rgba($color: #ffffff, $alpha: .2); }
.dark input::-webkit-input-placeholder { color: rgba($color: #ffffff, $alpha: .2); }


.dark input:-webkit-autofill,
.dark input:-webkit-autofill:hover, 
.dark input:-webkit-autofill:focus, 
.dark input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px var(--input-bg) inset !important;
	-webkit-text-fill-color: #fff !important;
	transition: none !important;
	border: 1px solid var(--input-border);
}


.show-password
{
	position: absolute;
	width: 50px;
	height: 50px;
	bottom: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}