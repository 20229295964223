$grey: #AAB8C9;
$green: #30D27A;
$orange: #F5803D;
$red: #E63756;

.monitoring-pie
{
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 20px 0 0;
	border: 1px solid var(--input-border);
	background-color: var(--box-bg);
	border-radius: 5px;
	max-width: 400px;

	@media (max-width: 500px) {
		max-width: 100%;
		margin-bottom: 20px;
	}

	.pie-info
	{
		width: 40%;
		p
		{
			font-size: 14px;

			span
			{
				width: 10px;
				height: 10px;
				display: inline-flex;
				border-radius: 100%;

				&.grey { background-color: $grey; }
				&.green { background-color: $green; }
				&.orange { background-color: $orange; }
				&.red { background-color: $red; }
			}
		}
	}
}



