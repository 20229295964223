.monitoring-stats
{
	padding: 50px 0;

	@media (max-width: 500px) {
		padding: 30px 0;
	}

	.info 
	{
		row-gap: 20px;


		.faded
		{
			text-transform: uppercase;
			color: var(--subheading);
			font-weight: 500;
		}
	}

	.tests
	{
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		justify-content: space-between;
		
		@media (max-width: 850px) {
			grid-template-columns: 1fr 1fr;
		}

		@media (max-width: 500px) {
			margin-top: 30px;
			grid-template-columns: 1fr;
		}

		.single-test
		{
			display: flex;
			align-items: center;
			background-color: var(--box-bg);
			margin-bottom: 5px;
			min-height: 70px;
			position: relative;

			&.customTooltip
			{
				position: relative;

				&::after 
				{
					content: attr(data-name);
					display: block;
					position: absolute;
					top: 50%;
					right: 60px;
					transform: translateY(-50%);
					padding: 0px 10px;
					font-size: 14px;
					border-radius: 3px;
					background-color: rgba(245, 128, 61, .1);
					color: #F5803D;
					opacity: 1;
					transition: .1s ease;
					font-weight: 500;
					text-transform: capitalize;

					@media (max-width: 500px) {
						top: 49px;
						right: unset;
						left: 0px;
						transform: unset;
						border-radius: 0;
						opacity: 0;
						visibility: hidden;
					}
				}

				&.harmless::after
				{
					background-color: rgba(48, 210, 122, .1);
					color: var(--green);
				}
				
				&.malicious::after
				{
					background-color: rgba(230, 55, 86, .1);
					color: var(--red);
				}

				&.uUndetected::after
				{
					background-color: rgba(95, 112, 132, .1);
					color: #5f7084;
				}

				&:hover::after
				{
					@media (max-width: 500px) {
						opacity: 1;
						visibility: visible;
					}
				}

			} 

			&:nth-child(3n)
			{
				p 
				{
					border-right: none;
				}
			}

			@media (max-width: 850px) {
				&:nth-child(3n)
				{
					p 
					{
						border-right: 1px solid var(--input-border);
					}
				}
				&:nth-child(2n)
				{
					p 
					{
						border-right: none;
					}
				}
			}

			@media (max-width: 500px) {
				p 
				{
					border-right: none !important;
				}
			}

			p
			{
				width: 100%;
				padding: 20px 0;
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-right: 1px solid var(--input-border);
				padding: 0 20px;
				color: var(--paragraph);
			}
		}
	}
}

.react-tabs__tab-list
{
	width: 100%;
	height: 70px;
	background-color: var(--box-bg);
	box-shadow: inset 0 -3px 0 0px var(--input-border);
	display: flex;
	align-items: center;
	font-size: 16px;
	font-weight: 600;
	padding: 0;
	margin: 0;
	border-radius: 5px;
	overflow: hidden;
	margin-bottom: 20px;

	.react-tabs__tab 
	{
		padding: 0px 30px;
		cursor: pointer;
		transition: .3s ease;
		height: 100%;
		display: flex;
		align-items: center;
		text-transform: uppercase;
		outline: none;
		color: var(--paragraph);

		&.react-tabs__tab--selected
		{
			color: var(--primary);
			box-shadow: inset 0 -3px 0 0px var(--primary);
		}

		&:hover
		{
			color: var(--primary);
		}
	}
}

.whois 
{

	textarea
	{
		width: 100%;
		overflow: visible;
		height: 500px;
		background-color: var(--box-bg);
		opacity: 1;
		padding: 20px;
		line-height: 33px;
		-webkit-appearance: none;
		resize: none;
		border: none;
		outline: none;
		border-radius: 5px;
		color: var(--paragraph);
	}
}
