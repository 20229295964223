.loading-screen
{
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 100;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh;
	background-color: var(--bg);


	svg
	{
		width: 400px;
		stroke-dasharray: 100;
  		animation: dash 7s linear;

		@media (max-width: 767px) {
			width: 250px;
		}
		
		@keyframes dash {
			to {
				stroke-dashoffset: 1000;
			}
		}
	}


}

.navigation
{
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 13px 2.5%;
	background-color: var(--navigation-bg);
	box-shadow: 0 5px 10px 0 rgba($color: #000000, $alpha: 0.05);
	position: relative;
	top: 0;
	transform: 0.3s ease;

	@media (max-width: 767px) {
		flex-direction: column;
		grid-gap: 20px;
	}

	.menu
	{
		display: flex;
		justify-content: center;
		align-items: center;
		
		a 
		{
			display: flex;
			padding: 10px 20px;

			svg
			{
				width: 20px;
				height: 20px;
				transition: 0.3s ease;
			}

			&:hover 
			{
				
				svg 
				{
					stroke: var(--blue);
				}
			}
		}
	}
}
